<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/label/articlelabellist')" class="el-icon-close"></i>
      </div>

      <div class="pt20"></div>
        <div class="pstion">
          <div style="display: flex;">编号:{{form.id}}<div style="margin-left:auto">{{form.status==1?'启用':'禁言'}}</div></div>
          <div>生成时间:{{form.created_at}}</div>
        </div>
    <div class="form">
      <el-form ref="form" :model="form" label-width="180px">
        <el-form-item label="一级分类名称">
            <el-input disabled v-model="form.name"></el-input>
          </el-form-item>
        <el-form-item label="排序">
            <el-input  v-model="form.sort"></el-input>
          </el-form-item>
          <el-form-item label="二级分类">
            <div class="flex" v-for="(item, index) in form.second"
          :key="index">
            <el-input v-model="item.name"></el-input>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deleteData(item, index)"
            ></el-button>
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              v-if="form.second.length - 1 === index"
              @click="add"
            ></el-button>
          </div>
          </el-form-item>
  


      <el-form-item>
        <el-button type="primary" v-if="!look" @click="onSubmit">确认</el-button>
      </el-form-item>
    </el-form>
</div>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
          second: [],
        },
        options:[],
        look:false,
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
     onSubmit() {
      let _this = this;
      let postdata = this.form;
      let arr = [];
      let length = postdata.second.length;
      for(let i in postdata.second){
          arr.push(postdata.second[i].name);
      }
      arr = Array.from(new Set(arr));
      if(arr.length != length){
        this.$alert("标签名重复");
        return;
      }
      console.log(postdata.sort)
      if(postdata.sort === ''){
        this.$alert("排序必填");
        return;
      }

      if(postdata.sort*1 != postdata.sort){
        this.$alert("排序只能为数字");
        return;
      }
      postdata.c_name = postdata.second;
      let obj = {
          id:postdata.id,
          sort:postdata.sort,
          c_name:postdata.c_name,
          updated_at:postdata.updated_at,
      }
      
      for(let i in postdata.c_name){//
        if(!postdata.c_name[i].name){
          _this.$alert("二级分类名不能为空");
          return;
        }
          arr.push(postdata.c_name[i].name);
      }
      arr = Array.from(new Set(arr));
      if(arr.length != length){
        this.$alert("二级分类名重复");
        return;
      }
      axios.post(config.labelCategory,obj)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.goNav('/label/articlelabellist');
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
    init(a){
        let _this = this;
        let obj = JSON.parse(a);
        _this.look = obj.look;
        axios.get(config.labelCategoryInfo,{params:{id:obj.id}})
        .then(function (response) {
            if(response.data.code == 200){
                _this.form = response.data.data;
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },// 增加
    add() {
        this.form.second.push({
          id: 0,
          name: ""
        });
    },
    // 删除
    deleteData(item, index) {
      if(item.second_label_count > 0){
        this.$alert("该分类正在被使用");
        return;
      }
      if (this.form.second.length - 1 < 1) {
        this.$alert("至少输入1项");
        return;
      } else {
        this.form.second.splice(index, 1);
      }
    },
  },
  created(){
     let a = sessionStorage.getItem('edit');
      this.init(a);
  }
}
</script>
<style scoped>
.pstion{
  position: absolute;
  right: 100px;
  text-align: left;
}
.form{
    width: 500px;
    margin-left: 200px;
}
.flex{
    display: flex;
}
.el-icon-close:before{
  font-size: 24px;
}
</style>
